/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */

.hero {
	position: relative;

	&__image {
		height: 670px;
	}

	.bar-link {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&__title {
		margin-bottom: 0;
		color: $white;
		font-size: 40px;
	}

	&__content {
		position: absolute;
		bottom: 167px;
		left: 0;
		right: 0;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.hero {
		&__image {
			height: 350px;
		}

		&__content {
			bottom: 20px;
		}
	}
}
