/* ------------------------------------------------------------ *\
	Sector
\* ------------------------------------------------------------ */

.sectors {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px -36px;

	.sector {
		width: 33.33%;
		padding: 0 15px;
	}

	&--quatro {
		.sector {
			width: 25%;
		}
	}
}

.sector {
	margin-bottom: 36px;

	&__inner {
		padding: 40px 40px 34px 40px; 
		border: 1px solid #C4C4C4;
		transition: background .4s;

		@media(min-width: 1025px){
			&:hover {
				background-color: #18B6C7;
				color: $white;

				.sector__link {
					color: $white;
				}

				.sector__icon {
					&-image {
						display: none;
					}

					&-image-hover {
						display: block;
					}
				}
			}
		}
	}

	&__icon {
		display: inline-flex;
		align-items: center;
		height: 72px;

		&-image-hover {
			display: none;
		}

		img {
			width: auto; 
			max-height: 100%;
		}
	}

	p {
		margin-top: 42px;
		margin-bottom: 20px;
		font-size: 20px;
		font-weight: 300;
		letter-spacing: 0.02em; 
	}

	&__link {
		color: #18B6C7;

		&:hover {
			color: #18B6C7;
		}

		&--green {
			color: #C9CE3D;

			&:hover {
				color: #C9CE3D;
			}
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.sectors {
		.sector {
			width: 50%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.sectors {
		.sector {
			width: 100%;
		}
	}
}
