/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	white-space: normal;

	&:focus,
	&.focus {
		box-shadow: none;
	}
}

.btn-submit {
	min-width: 370px;
	height: 41px;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0.05em;
	color: #18B6C7;
	border-radius: 0;
	background-color: transparent;
	border: 1px solid #000000;
	transition: all .4s;

	&:hover {
		background-color: #18B6C7;
		color: $white;
	} 
}

.btn-primary {
	@include button-variant(red, green, blue, pink, yellow);

	color: black;

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		border-color: coral;

		&:focus {
			box-shadow: none;
		}
	}

	&:focus,
	&.focus {
		box-shadow: none;
	}
}

.btn-outline-primary {
	@include button-outline-variant(red, green, blue, pink);

	color: yellow;

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		&:focus {
			box-shadow: none;
		}
	}

	&:focus,
	&.focus {
		box-shadow: none;
	}
}
