/* ------------------------------------------------------------ *\
	Slider Hero
\* ------------------------------------------------------------ */

.slider-hero {
	.hero__content {
		position: absolute;
		bottom: 65px;
		left: 0;
		right: 0;
		z-index: 10;
	}

	.hero__title {
		margin-bottom: 40px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.slider-hero {
		.hero__content {
			bottom: 20px;
		}
	}
}
