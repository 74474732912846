/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	& > ul {
		display: flex;
		align-items: center;
		padding-left: 0;
		list-style-type: none; 
		font-size: 18px;
		font-weight: 600;
	}

	& > ul > li + li {
		margin-left: 30px;
	}

	& > ul > li {
		position: relative; 

		ul {
			opacity: 0;
			visibility: hidden; 
			padding: 10px 5px;
			position: absolute; 
			top: 100%; 
			left: 0; 
			z-index: 5; 
			width: 200px; 
			background: #001a31;
			color: #fff;
			text-align: center; 
			font-size: 14px;
			text-decoration: none; 
			transition: opacity .4s, visibility .4s;
			list-style-type: none;

			a:hover {
				color: #fff;
				text-decoration: none;
			}
		}
	}

	& > ul > li.hover:hover {
		ul {
			opacity: 1;
			visibility: visible;
		}
	}

	& > ul > li > a {
		color: $black;
		transition: all .4s;

		@media(min-width: 1025px){
			&:hover {
				color: $blue;
				text-decoration: none;
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.nav {
		& > ul {
			width: 100%; 
		}

		& > ul > li {
			ul {
				display: none;
				position: static; 
				width: auto;
				background: transparent;
				opacity: 1;
				visibility: visible;
				text-align: left;

				li a {
					color: #001a31;

					&:hover {
						color: #001a31;
					}
				}
			}
		}

		& > ul {
			font-size: 16px;
		}
	}
}
