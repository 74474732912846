/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widget {
	&__title {
		font-size: 16px;
		letter-spacing: 0.05em;
		font-weight: 600;
		color: #18B6C7; 
	}

	&__list {
		padding-left: 0;
		list-style-type: none;
		font-size: 16px;
		letter-spacing: 0.05em;

		a {
			color: $black;

			span {
				font-weight: 600;
			}
		}  
	}

	& + & {
		margin-top: 40px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.widget {
		& + & {
			margin-top: 20px;
		}
	}
}
