/* ------------------------------------------------------------ *\
	Testimonial
\* ------------------------------------------------------------ */

.testimonial {
	display: flex;
	align-items: center;

	&__image {
		width: 370px;

		&:after {
			display: block;
			padding-top: 100%;
			content: '';
		}
	}

	&__content {
		flex: 1;
		padding-left: 130px;
	}

	&__entry {
		font-size: 16px;
		letter-spacing: 0.05em;
		font-weight: 300;
		line-height: 1.56; 
	}

	&__author {
		margin-top: 30px;
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0.05em;

		span {
			color: #CAD754;
		} 
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.testimonial {
		display: block;

		&__image {
			margin: 0 auto;
		}

		&__content {
			width: 100%;
			padding-left: 0;
			margin-top: 20px;
		}
	}
}
