/* ------------------------------------------------------------ *\
	FIeld
\* ------------------------------------------------------------ */

.field {
	width: 100%;
	height: 41px;
	padding: 0 15px;
	border-radius: 0;
	border: 1px solid #000000; 
	font-size: 14px;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	font-weight: 300;

	&--textarea {
		height: 263px;
		padding: 12px;
	}
}

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
	&__row {
		margin-bottom: 30px;
	}
}
