/* ------------------------------------------------------------ *\
	Link
\* ------------------------------------------------------------ */

.link {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.05em; 
	color: #D1E8DE;
	text-transform: uppercase;

	&:hover {
		color: #D1E8DE;
	}

	&--green {
		color: #CAD754;

		&:hover {
			color: #CAD754;
		}
	}

	&--blue {
		color: #18B6C7;

		&:hover {
			color: #18B6C7;
		}
	}
}
