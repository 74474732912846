/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$assetsPath: '../';

$primary-font: 'Proxima Nova', sans-serif;

/* ------------------------------------------------------------ *\
	Bootstrap Variables
\* ------------------------------------------------------------ */

/*  Fonts  */
$font-family-sans-serif: $primary-font;

$line-height-base: 1.56;

/*  Paragraphs  */
$paragraph-margin-bottom: 1.56em;

/*  Container  */
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px
);

/*  Grid Breakpoints  */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1281px
) !default;

$blue: #18B6C7;
