/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 15;
	padding: 44px 0 24px; 
	border-bottom: 1px solid #EEEEEE;
	background-color: $white;

	&--transparent {
		background-color: transparent;
		border-bottom: 0;

		&.active {
			border-bottom: 1px solid #EEEEEE;
			background-color: $white;
		}
	}

	&--white {
		.nav & > ul > li > a {
			color: $white;

			&:hover {
				color: #18B6C7;
			}
		}

		.socials a svg path {
			fill: $white;
		}

		&.active {
			.nav > ul > li > a {
				color: $black;
			}

			.socials a svg path {
				fill: #18B6C7;
			}
		}
	}

	&--black {
		.nav > ul > li > a {
			color: $black;

			&:hover {
				color: #18B6C7;
			}
		}

		.socials a svg path {
			fill: $black;
		}

		&.active {
			.nav ul a {
				color: $black;
			}

			.socials a svg path {
				fill: #18B6C7;
			}
		}
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__aside {
		/* display: flex; */
		/* align-items: center; */
	}

	.socials {
		margin-bottom: 20px;
		ul {
			justify-content: flex-end;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.header {
		padding: 15px 0;

		&__aside {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			display: none;
			padding: 15px;
			border-bottom: 1px solid #000; 
			background-color: $white;
		}

		.nav {
			& > ul {
				display: block;

				& > li > a {
					color: $black !important;
				}
			}

			& > ul > li + li {
				margin: 10px 0 0 0;
			}
		}

		.socials {
			ul {
				justify-content: flex-start;
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.header {
		.logo {
			width: 100px; 
		}
	}
}
