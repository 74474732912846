/* ------------------------------------------------------------ *\
	Nav Dots
\* ------------------------------------------------------------ */

.nav-dots {
	display: flex;
	align-items: center;
	font-size: 0;
	line-height: 0;

	.swiper-pagination-bullet {
		width: 15px;
		height: 15px;
		margin: 0 5px !important;
		opacity: 1;
		background-color: #fff;
		border-radius: 0;
		transition: all .4s;

		&-active {
			width: 15px;
			height: 15px;
			background-color: $blue;
		}
	}
}
