/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper {
	min-height: 100vh;
	position: relative;
	overflow: hidden;

	&.gutter {
		padding-top: 151px;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.wrapper.gutter {
		padding-top: 113px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.wrapper.gutter {
		padding-top: 86px;
	}
}
