/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article {
	&__title {
		margin-bottom: 40px;
	}

	&__link {
		margin-bottom: 37px;
	}

	&__entry {
		img {
			width: 100%;
			margin-bottom: 3.5em; 
		}
	}

	&--small {
		.article__entry p {
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0.05em; 
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.article {
		&__title {
			margin-bottom: 20px;
		}
	}
}
