/* ------------------------------------------------------------ *\
	Bar
\* ------------------------------------------------------------ */

.bar {
	padding: 92px 0;
	background-color: #CAD754;
	color: $white;

	&__title {
		margin-bottom: 30px;
		font-size: 40px;
		font-weight: 600;
	}

	&__meta {
		font-size: 18px;
		letter-spacing: 0.05em;
		font-weight: 600; 
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.bar {
		padding: 30px 0;

		&__title {
			margin-bottom: 10px;
			font-size: 30px;
		}
	}
}
