/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	font-family: $font-family-sans-serif, sans-serif;
	font-size: 16px;
}

ul:last-child,
ol:last-child,
p:last-child { 
	margin-bottom: 0; 
}

p {
	font-weight: 300;
	letter-spacing: 0.06em; 
}

img {
	max-width: 100%;
	height: auto;
}

h1, .h1 {
	font-size: 30px;
	font-weight: 600;
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	h1, .h1 {
		font-size: 24px;
	}
}
