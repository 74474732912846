/* ------------------------------------------------------------ *\
	Boxes
\* ------------------------------------------------------------ */

.boxes {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px -80px;

	.box {
		width: 33.33%;
		padding: 0 15px;
		margin-bottom: 80px;
	}
}

.box {
	&__inner-image-container {
		position: relative;
		overflow: hidden;
		margin-bottom: 45px;

		&:hover .hover-overlay {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}
	}

	&__inner-image {
		&:after {
			display: block;
			padding-top: 100%;
			content: '';
		}
	}

	&__inner-entry {
		margin-bottom: 10px;
		font-size: 18px;
	} 
} 

/* === Tablet === */
@include media-breakpoint-down(md) {
	.boxes {
		.box {
			width: 50%;

			.hover-overlay {
				display: none;
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.boxes {
		margin-bottom: -30px;

		.box {
			width: 100%;
			margin-bottom: 30px;
		}
	}

	.box {
		&__inner-image-container {
			margin-bottom: 20px;
		}
	}
}
