/* ------------------------------------------------------------ *\
	Plain text
\* ------------------------------------------------------------ */

.plain-text {
	display: flex;
	margin: 0 -15px;

	&__col {
		width: 50%;
		padding: 0 15px;

		&-title {
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 0.05em;
			color: #14B5C6;

			&--green {
				color: #CAD754;
			} 

			&--lightblue {
				color: #D1E8DE;
			}
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.plain-text {
		display: block;

		&__col {
			width: 100%;

			& + & {
				margin-top: 20px;
			}
		}
	}
}
