/* ------------------------------------------------------------ *\
	Project
\* ------------------------------------------------------------ */

.projects {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px -22px;

	.project {
		width: 33.33%;
		padding: 0 15px;
		margin-bottom: 22px;
	}

	&--4 .project {
		width: 25%;
	}
}

.project {
	&__image-container {
		position: relative;
		overflow: hidden;

		&:hover .hover-overlay {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}
	}

	&__image-image {
		&:after {
			display: block;
			padding-top: 100%;
			content: '';
		}
	}

	&--high {
		.project__image-image:after {
			padding-top: 156.76%;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.projects {
		.project {
			width: 50%;

			.hover-overlay {
				margin-top: 20px;

				&__entry {
					margin-bottom: 10px;
				}
			}

			&--high {
				.project__image-image:after {
					padding-top: 100%;
				}
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.projects {
		.project {
			width: 100%;
		}
	}
}
