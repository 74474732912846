/* ------------------------------------------------------------ *\
	Hover Overlay
\* ------------------------------------------------------------ */

@media(min-width: 1025px){
	.hover-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0; 
		background: rgba(34, 34, 40, 0.90);
		transform: translateX(-150px);
		opacity: 0;
		visibility: hidden;
		transition: all .4s;

		span {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 9px;

			&:nth-child(1) {
				left: 0;
				background-color: #D1E8DE;
			}

			&:nth-child(2) {
				left: 9px;
				background-color: #CAD754;
			}

			&:nth-child(3) {
				left: 18px;
				background-color: #14B5C6;
			}
		}

		&__content {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding-left: 70px;
			padding-bottom: 37px;
			padding-right: 30px;
		}

		&__entry {
			margin-bottom: 11px;
			font-size: 18px;
			color: $white;
			font-weight: 600;
		}
	}
}
