/* ------------------------------------------------------------ *\
	Background
\* ------------------------------------------------------------ */

.background {
	&:after {
		display: block;
		padding-top: 23.14%; 
		content: '';
	}
}
