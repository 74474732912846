/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	position: relative;
	padding: 98px 0;
	background-color: #222228;

	&__decoration {
		span {
			position: absolute;
			left: 0;
			right: 0;
			height: 9px;
			z-index: 5;

			&:nth-child(1) {
				top: -27px;
				background-color: #14B5C6;
			}

			&:nth-child(2) {
				top: -18px;
				background-color: #CAD754;
			}

			&:nth-child(3) {
				top: -9px;
				background-color: #D1E8DE;
			}
		}
	}

	@media(min-width: 1281px){
		.container {
			min-width: 1290px;
		}
	}

	&__title {
		margin-bottom: 36px;
		color: $blue;
		letter-spacing: 0.02em; 
	}

	&__row {
		display: flex;
	}

	&__col--size1 {
		width: 64%;
	}

	&__col--size2 {
		width: 36%;
	}

	&__contacts {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;

		.footer__contact {
			padding: 0 10px;

			p {
				margin-bottom: 50px;
				color: $white;
				font-size: 20px;
				font-weight: 300;
				letter-spacing: 0.02em;
				line-height: 1.25;

				strong {
					font-weight: 600;
				}
			}
		}

		.footer__contact--size1 {
			width: 36%; 
		}

		.footer__contact--size2 {
			display: flex;
			flex-wrap: wrap;
			width: 64%;

			p {
				width: 50%;
			}
		}

		ul {
			padding-left: 0;
			list-style: none; 
		}

		a {
			font-size: 20px;
			color: $white;
			font-weight: 600;
			letter-spacing: 0.02em; 
		}
	}

	&__logo {
		margin-top: 75px;
	}

	&__nav {
		ul {
			display: flex;
			flex-wrap: wrap;
			padding-left: 0;
			list-style-type: none; 
			margin: 0 -10px -9px;
		}

		a {
			color: $white;
			font-size: 20px;
			font-weight: 600;
			letter-spacing: 0.02em; 
		}

		li {
			width: 50%;
			padding: 0 10px;
			margin-bottom: 9px;
		}
	}

	&__copyright {
		margin-top: 94px;
		
		p {
			font-size: 20px;
			font-weight: 300;
			color: $white;
			letter-spacing: 0.02em; 
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.footer {
		&__row {
			display: block;
		}

		&__col {
			width: 100% !important;
		}

		&__col + &__col {
			margin-top: 20px;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.footer {
		&__contacts {
			display: block;

			.footer__contact {
				width: 100% !important;
			}

			.footer__contact + .footer__contact {
				margin-top: 20px;
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.footer {
		padding: 40px 0;

		&__logo {
			max-width: 100px;
		}

		&__decoration {
			display: none;
		}

		&__title {
			margin-bottom: 20px;
		}

		&__logo {
			margin-top: 20px;
		}

		&__contacts {
			.footer__contact p {
				width: 100%;
				margin-bottom: 20px;
				font-size: 16px;
			}

			ul a {
				font-size: 16px;
			}
		}

		&__nav {
			ul a {
				font-size: 16px;
			}
		}
		
		&__copyright {
			margin-top: 20px;
			
			p {
				font-size: 16px;
			}
		}
	}
}
