/* ------------------------------------------------------------ *\
	Logos
\* ------------------------------------------------------------ */

.logos {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 -14px -30px;

	.logo {
		width: 16.667%;
		padding: 0 14px;
		margin-bottom: 30px;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.logos {
		.logo {
			width: 25%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.logos {
		margin: 0 -10px -20px;
		
		.logo {
			width: 50%;
			padding: 0 10px;
		}
	}
}
