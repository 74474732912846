/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
	ul {
		display: flex;
		align-items: center;
		padding-left: 0; 
		list-style-type: none;
	}

	li + li {
		margin-left: 27px;
	}

	a {
		transition: all .4s;
	}

	a:hover {
		opacity: .75
	}
}
