/* ------------------------------------------------------------ *\
	Page Actions
\* ------------------------------------------------------------ */

.page-actions {
	padding: 30px 0;
	background: rgba(179, 192, 201, 0.2);

	&--transparent {
		background-color: transparent;
	}

	&--blue {
		.page-actions__inner {
			a {
				font-size: 16px;
				color: $blue;
			}
		}
	}

	&__inner {
		display: flex;
		justify-content: space-between;

		a {
			display: inline-flex;
			align-items: center;
			font-weight: 600;
			font-size: 14px;
			letter-spacing: 0.05em;
			color: $black;

			&:first-child {
				img {
					margin-right: 15px;
				}
			} 

			&:last-child {
				img {
					margin-left: 15px;
				}
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.page-actions {
		&--blue {
			.page-actions__inner {
				a {
					font-size: 12px;
				}
			}
		}

		&__inner {
			a {
				font-size: 12px;
			}
		}
	}
}
