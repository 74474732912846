/* ------------------------------------------------------------ *\
	Contacts
\* ------------------------------------------------------------ */

.contacts {
	display: flex;

	&__aside {
		width: 400px;
		padding-right: 20px;

		ul {
			font-size: 20px;
			letter-spacing: 0.02em;
			line-height: 1.25;
			padding-left: 0;
			list-style-type: none;

			li + li {
				margin-top: 10px;
			}

			a {
				display: inline-flex;
				align-items: center;
				font-weight: 600;
				color: #222228;

				img {
					margin-right: 37px;
				}
			}
		}
	}

	ul.info {
		li + li {
			margin-top: 50px;
		}
	}

	&__title {
		margin-bottom: 38px;
	}

	ul + &__title {
		margin-top: 60px;
	}

	&__form {
		flex: 1;
	}

	&__socials {
		margin-top: 175px;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.contacts {
		display: block;

		&__aside {
			width: 100%;
			margin-bottom: 20px;
		}

		ul + &__title {
			margin-top: 20px;
		}

		&__title {
			margin-bottom: 20px;
		}

		ul.info {
			li + li {
				margin-top: 10px;
			}
		}

		&__socials {
			margin-top: 50px;
		}

		&__form {
			width: 100%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.contacts {
		&__form {
			.btn-submit {
				min-width: 100%;
			}
		}
	}
}
