/* ------------------------------------------------------------ *\
	Heading
\* ------------------------------------------------------------ */

.heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;

	.title {
		margin-bottom: 0 !important;
		text-transform: uppercase;
		letter-spacing: 0.02em; 
	}

	.actions {
		button {
			@include reset-button;
			
			svg path {
				transition: all .4s;
			}

			&:hover svg path {
				fill: $blue;
			}
		}

		button + button {
			margin-left: 20px;
		}
	}
}
