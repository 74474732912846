/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	position: relative;
	margin: 100px 0;

	&--decorated {
		padding-bottom: 100px;
		margin-bottom: 0;

		.box {
			color: $white;
		}

		&:before {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 50%;
			background-color: #222228;
			content: '';
		}
	}

	&__inner {
		max-width: 870px;
		margin: 0 auto;
	}

	.title {
		margin-bottom: 55px;

		&--decorated {
			position: relative;
			display: inline-block;

			&:after {
				position: absolute;
				bottom: 4px;
				left: 0;
				right: -15px;
				z-index: -1;
				height: 9px;
				content: '';
			}

			&-green:after {
				background-color: #CAD754;
			}

			&-lightblue:after {
				background-color: #D1E8DE;
			}
		}
	}

	&__entry {
		margin-bottom: 70px;

		p {
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0.05em; 
		}
	}

	&__grid {
		display: flex;
	}

	&__filters {
		ul {
			display: flex;
			justify-content: center;
			list-style-type: none;
			padding-left: 0;
			margin: 0;
		}

		li + li:before {
			content: '|';
			display: inline-block;
			margin: 0 10px;
		}
	}

	&__content {
		flex: 1;

		&-head {
			margin-bottom: 70px;

			.section__content-title {
				margin-bottom: 20px;
			}
		}

		&-title {
			margin-bottom: 70px;
		} 
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.section {
		.title {
			margin-bottom: 20px;
		}
		
		&__grid {
			display: block;
		}

		&__sidebar {
			width: 100%;
		}

		&__content {
			padding-left: 0;
			margin-top: 20px;

			&-head {
				margin-bottom: 20px;
			}

			&-title {
				margin-bottom: 20px;
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.section {
		margin: 50px 0;

		&__filters {
			ul {
				display: block;
				text-align: center;

				li + li:before {
					display: none;
				}
			}
		}

		&--decorated {
			padding-bottom: 50px;
			margin-bottom: 0;
		}
	}
}
