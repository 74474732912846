/* ------------------------------------------------------------ *\
	Related
\* ------------------------------------------------------------ */

.related {
	margin-top: 60px;

	&__title {
		margin-bottom: 42px;
	}
}
