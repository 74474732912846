/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */

.container {
	&--large {
		max-width: 1530px;
	}

	&--small {
		max-width: 996px;
	}

	&--xsmall {
		max-width: 830px;
	}
}
