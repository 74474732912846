/* ------------------------------------------------------------ *\
	Panels
\* ------------------------------------------------------------ */

.panels {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -17px -34px;

	.panel {
		width: 33.33%;
		padding: 0 17px;
		margin-bottom: 34px;
	}
}

.panel {
	&__inner {
		position: relative;
		overflow: hidden;

		.hover-overlay {
			left: 0px;
			top: 0px;
			right: 0px;
			bottom: 0px;
			z-index: 5; 
		}

		&:hover .hover-overlay {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}
	}

	&__redirect {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;
	}

	&__title {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 5;
		padding: 29px 40px 49px 40px; 
		background-color: $blue;
		color: $white;

		a {
			color: $white;

			&:hover {
				color: $white;
				text-decoration: underline;
			}
		}

		h5 {
			margin-bottom: 0;
			font-size: 20px;
			font-weight: 600;
			letter-spacing: 0.02em; 
		}
	}

	&__bar {
		height: 20px;
		background-color: $blue;
	}

	&__image {
		&:after {
			display: block;
			padding-top: 158.9%; 
			content: '';
		}
	}

	&--green {
		.panel__bar,
		.panel__title {
			background-color: #CAD754;
		}
	}

	&--lightblue {
		.panel__bar,
		.panel__title {
			background-color: #D1E8DE;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.panels {
		.panel {
			width: 50%;

			&__redirect {
				display: block;
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.panels {
		.panel {
			width: 100%;
		}
	}
}
