/* ------------------------------------------------------------ *\
	Bar Link
\* ------------------------------------------------------------ */

.bar-link {
	padding: 15px 0;
	background-color: $blue;
	color: $white;
	transition: opacity .4s;

	.container {
		display: block;
	}

	&__inner {
		display: flex;
		justify-content: space-between;
	}

	&__text {
		font-size: 40px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 0.02em; 
	}

	&:hover {
		opacity: .85;
		text-decoration: none;
		color: $white;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.bar-link {
		&__text {
			font-size: 16px;
		}
	}
}
