/* ------------------------------------------------------------ *\
	Details
\* ------------------------------------------------------------ */

.details {
	&__photos {
		display: flex;
		justify-content: space-between;
	}

	&__photo {
		width: 766px;
		height: 580px;
	}

	&__photo--small {
		width: calc(100% - 766px - 34px); 
	}

	&__meta {
		p {
			font-size: 18px;
			font-weight: 600;
			letter-spacing: 0.05em; 
		}
	}

	&__link {
		margin-top: 40px;
	}

	&__title {
		letter-spacing: 0.02em; 
	}

	&__entry {
		margin-top: 33px;
		font-size: 18px;
		font-weight: 300;
		line-height: 1.39;
		letter-spacing: 0.05em; 
	}

	&__content {
		display: flex;
		margin-top: 62px;
		margin-bottom: 63px;

		&-left {
			width: 760px;
		}

		&-right {
			flex: 1;
			padding-left: 140px;
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.details {
		&__photo {
			width: 50%;
			height: 250px;
		}

		&__content {
			display: block;
			margin-top: 20px;

			&-left {
				width: 100%;
			}

			&-right {
				width: 100%;
				padding-left: 0; 
				margin-top: 20px;
			}
		}
	}
}
