/* ------------------------------------------------------------ *\
	Accordion
\* ------------------------------------------------------------ */

.accordion-holder {
	margin-top: 40px;
}

.accordion {
	& + & {
		margin-top: 20px;
	}

	& > a {
		display: inline-block;
		padding-bottom: 13px;
		border-bottom: 1px solid #EEEEEE;
		margin-bottom: 13px; 

		&:hover {
			text-decoration: none;
		}
	}

	& > a svg {
		margin-left: 40px;
	}

	& > ul {
		display: none;
		padding-left: 0;
		list-style-type: none; 
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.accordion-holder {
		margin-top: 20px;
	}
	
	.accordion {
		& + & {
			margin-top: 10px;
		}

		& > a {
			padding-bottom: 5px;
			margin-bottom: 5px;
		}
	}
}
