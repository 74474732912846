/* ------------------------------------------------------------ *\
	Text Icon
\* ------------------------------------------------------------ */

.text-icon {
	display: flex;
	padding-bottom: 10px;
	border-bottom: 1px solid #EEEEEE; 

	&__icon {
		width: 160px;
	}

	&__entry {
		flex: 1;
		padding: 0 130px;
		
		p {
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0.05em;
			line-height: 1.39;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.text-icon {
		&__entry {
			padding: 0 0 0 30px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.text-icon {
		display: block;

		&__entry {
			padding: 0;
			margin-top: 20px;
		}
	}
}
