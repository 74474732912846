/* ------------------------------------------------------------ *\
	Member
\* ------------------------------------------------------------ */

.members {
	font-size: 0;
	line-height: 0;
	margin: 0 -18px -50px;

	.member {
		position: relative;
		display: inline-block;
		width: 33.33%;
		padding: 0 18px;
		margin-bottom: 50px;
		vertical-align: top;
	}
}

.member {
	&__image {
		&:after {
			display: block;
			padding-top: 101%;
			content: '';
		}
	}

	&__inner {
		position: relative;
		overflow: hidden;

		&:hover {
			.member__overlay {
				opacity: 1;
				visibility: visible; 
			}
		}
	}

	&__overlay {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 20px;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 50%;
		opacity: 0;
		visibility: hidden;
		transition: all .4s;

		&-inner {
			width: 100%;
		}

		.member__text-title,
		.member__text-subtitle {
			color: $white;
			text-align: center;
		}
	}

	&.active {
		.member__text {
			display: block;
		}
	}

	&__text {
		display: none;
		padding-top: 75px;

		&-title {
			color: $blue;
			font-size: 20px;
			font-weight: 600;
			letter-spacing: 0.15em;
			text-transform: uppercase; 
		}

		&-subtitle {
			margin-bottom: 30px;
			font-size: 20px;
			font-weight: 300;
			letter-spacing: 0.05em; 
		}

		&-entry {
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0.05em;
			line-height: 1.67; 
		}
	}
}

.member:nth-child(3n+1) .member__text {
	margin-right: -216%;
}

.member:nth-child(3n+2) .member__text {
	margin: 0 -108%;
}

.member:nth-child(3n+3) .member__text {
	margin-left: -216%;
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.members {
		.member {
			width: 50%;
		}
	}

	.member {
		&__text {
			display: block;
			padding-top: 30px;
			margin: 0 !important;
		}

		&__overlay {
			display: none;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.members {
		.member {
			width: 100%;
		}
	}
}
