/* ------------------------------------------------------------ *\
	SLider
\* ------------------------------------------------------------ */

.slider {
	&__slide-image {
		&:after {
			display: block;
			padding-top: 58.12%;
			content: '';
		}
	}

	&__arrows {
		position: absolute;
		bottom: 25px;
		right: 25px;
		z-index: 5;
		
		button {
			@include reset-button;
			width: 30px;
			height: 30px;
			padding: 0;

			&:hover {
				svg g rect {
					opacity: 1;
				}
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.slider {
		&__arrows {
			bottom: 10px;
			right: 10px;
		}
	}
}
