/* ------------------------------------------------------------ *\
	Grid Images
\* ------------------------------------------------------------ */

.grid-images {	
	margin: -8px;
	
	&__holder {
		position: relative;
		width: 65%;
		padding: 8px;
		overflow: hidden;
		
		.hover-overlay {
			left: 8px;
			top: 8px;
			right: 8px;
			bottom: 8px;
		}

		&:hover .hover-overlay {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}
	}

	&__image {
		&:after {
			content: '';
			display: block; 
			padding-top: 100%; 
		}
	}

	&__holder--square-small {
		width: calc(35% - 30px);
		padding: 8px;
	}

	&__holder--rectangle-large {
		width: 65%;

		.grid-images__image:after {
			padding-top: 48.5%;
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.grid-images {
		&__holder {
			width: 50% !important;
		}

		&__image:after {
			padding-top: 56.25% !important;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.grid-images {
		&__image {
			margin-bottom: 10px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.grid-images {
		&__holder {
			width: 100% !important;
		}

		&__image:after {
			padding-top: 100% !important;
		}
	}
}
