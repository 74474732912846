/* ------------------------------------------------------------ *\
	Paging
\* ------------------------------------------------------------ */

.paging {
	display: flex;
	margin-top: 65px;

	&__button + &__button {
		margin-left: 10px;
	}

	&__button {
		@include reset-button;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		background-color: #222228;
		color: $white;
		font-size: 16px;
		font-weight: 600;

		&.active {
			color: $blue;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.paging {
		margin-top: 30px;
	}
}
